import React, { Fragment, useEffect, useRef } from 'react';
import Message from '../message/message';
import '../PublicChat.css';
import ScrollToBottom from 'react-scroll-to-bottom';

const Containermessage = ({
  visitors,
  setChangedata,
  ownerMode,
  bellNotification,
  textColor,
  statusAvatar,
  setUserMessagePrivate,
  isModeAdmin,
  openChat
}) => {
  const scrollDiv = useRef(null);
  const scrollToBottom = () => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [visitors]);

  // regresar a el ultimo mensaje
  useEffect(() => {
    if (openChat) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
      return;
    }
  }, [openChat]);

  const sinEfe = () => null;

  return (
    <Fragment>
      <ScrollToBottom className="container-messages-wp">
        <div className="sctopmessage"></div>

        {Array.isArray(visitors)
          ? visitors.map((message, i) => {
              return (
                <Message
                  key={i}
                  message={message.message}
                  setChangedata={
                    message.autorid === window.sessionStorage.getItem('user_Id')
                      ? setChangedata
                      : sinEfe
                  }
                  name={message.name}
                  data={message.createdAt}
                  avatarStatus={message.avatarStatus}
                  avatar={message.avatar}
                  textColor={textColor}
                  bellNotification={bellNotification}
                  ownerMode={ownerMode}
                  color={message.color}
                  isModeAdmin={isModeAdmin}
                  isAdmin={message.isAdmin}
                  userId={message.autorid}
                  privat={message.private}
                  recipient={message.recipient}
                  setUserMessagePrivate={setUserMessagePrivate}
                  authorRole={message.role} // Asegúrate de que cada mensaje tenga un campo `role`
                />
              );
            })
          : null}

        <div ref={scrollDiv} className="scmessage"></div>
      </ScrollToBottom>
    </Fragment>
  );
};

export default Containermessage;
