import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as RightIconBlack } from 'assets/whiteboard/black/right-black.svg';
import { ReactComponent as RightIconGrey } from 'assets/whiteboard/grey/right-grey.svg';
import { ReactComponent as RightIconWhite } from 'assets/whiteboard/white/right-white.svg';
import { ifIsAudioComponentOpenEmit, getAudioPageValue } from 'utils/audioComponentUtils';
// enums
import { ICONS_COLORS } from '../../../enums/constants.enum';
import * as Actions from '../../../store/actions/WhiteBoardActions';

const PageRight = ({ whiteBoardSocket, color, shape, requestUpdatePage, page }) => {
  const goToPage = () => {
    const shapeValues = Object.values(shape);
    const initialPageValue = getAudioPageValue(shapeValues);
    const pageValue = initialPageValue + 1;

    ifIsAudioComponentOpenEmit(
      shapeValues,
      pageValue,
      initialPageValue,
      whiteBoardSocket
    );
    requestUpdatePage({ page: page + 1 });
  };

  return (
    <div onClick={goToPage}>
      {color === ICONS_COLORS.BLACK && (
        <RightIconBlack className="page-flecha-icon-item" />
      )}
      {color === ICONS_COLORS.WHITE && (
        <RightIconWhite className="page-flecha-icon-item" />
      )}
      {color === ICONS_COLORS.GREY && <RightIconGrey className="page-flecha-icon-item" />}
    </div>
  );
};

const mapStateToProps = state => ({
  page: state.whiteBoard.page,
  shape: state.whiteBoard.shapes
});

const mapDispatchToProps = dispatch => {
  const { requestUpdatePage } = Actions;

  return bindActionCreators({ requestUpdatePage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PageRight);
